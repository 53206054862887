/** @jsx jsx */
import { jsx } from "theme-ui";
import { FC, useLayoutEffect, useState, memo } from "react";
import styled, { css } from "styled-components";
import { Flex, Box } from "rebass";
import { ULink } from "../components/ULink";
import Eye from "../icons/Eye";
import useIsScrolled from "../hooks/useIsScrolled";
import ThemeSwitch from "../components/ThemeSwitch";

interface HeaderProps {
  bgColor: string;
  textColor: string;
}

const Header: FC<HeaderProps> = ({ bgColor, textColor }) => {
  const isScrolled = useIsScrolled(56);
  const [isHeroVisible, setIsHeroVisible] = useState(true);

  useLayoutEffect(() => {
    const handleScroll = () => {
      const heroSection = document.getElementById("hero-section");
      if (heroSection) {
        const rect = heroSection.getBoundingClientRect();
        setIsHeroVisible(rect.bottom > 0);
      }
    };

    handleScroll();
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Flex
      flexWrap="wrap"
      alignItems="center"
      variant="containerHeader"
      color="heading"
      justifyContent="space-between"
      className={isHeroVisible ? "hero-visible" : "hero-hidden"}
      sx={{
        color: isHeroVisible ? textColor : "text",
      }}
    >
      <Box width={[1 / 1, 1 / 1, 1 / 1, 1 / 3, 1 / 3]}>
        <NavLeft>
          <Logo>
            <ULink to="/" activeClassName="active">
              <Eye />
            </ULink>
          </Logo>
        </NavLeft>
      </Box>

      <Box
        width={[1 / 1, 1 / 1, 1 / 1, 1 / 3, 1 / 3]}
        sx={{ position: "relative" }}
        height={["48px"]}
      >
        <MainNav
          isScrolled={isScrolled}
          sx={{
            color: isHeroVisible ? textColor : "text",
            backgroundColor: "backgroundElevationOpacity",
            p:[1],
          }}
        >
          <ULink
            to="/work"
            activeClassName="active"
            partiallyActive={true}
            sx={{ 
              variant: "links.nav", 
              "&.active": { 
                color: isHeroVisible ?  bgColor: "background",
                bg: isHeroVisible ? textColor : "text"
              },
              "&:hover": { 
                color: isHeroVisible ?  bgColor: "text",
                bg: isHeroVisible ? textColor : "backgroundElevationOpacity"
              },
              "&.active:hover": {
                opacity: "0.64"
              }  
            }}
          >
            Work
          </ULink>
          <ULink
            to="/process"
            activeClassName="active"
            partiallyActive={true}
            sx={{ 
              variant: "links.nav", 
              "&.active": { 
                color: isHeroVisible ?  bgColor: "text",
                bg: isHeroVisible ? textColor : "backgroundElevationOpacity"
              },
              "&:hover": { 
                color: isHeroVisible ?  bgColor: "text",
                bg: isHeroVisible ? textColor : "backgroundElevationOpacity"
              },
              "&.active:hover": {
                opacity: "0.64"
              }  
            }}
          >
            Method
          </ULink>
          <ULink
            to="/about"
            activeClassName="active"
            partiallyActive={true}
            sx={{ 
              variant: "links.nav", 
              "&.active": { 
                color: isHeroVisible ?  bgColor: "text",
                bg: isHeroVisible ? textColor : "backgroundElevationOpacity"
              },
              "&:hover": { 
                color: isHeroVisible ?  bgColor: "text",
                bg: isHeroVisible ? textColor : "backgroundElevationOpacity"
              },
              "&.active:hover": {
                opacity: "0.64"
              }  
            }}
          >
            About
          </ULink>
        </MainNav>
      </Box>

      <Box width={[1 / 1, "auto", "auto", 1 / 3, 1 / 3]}>
        <NavRight>
          <ThemeSwitch
            bgColor={isHeroVisible ? bgColor : "text"}
            textColor={isHeroVisible ? textColor : "backgroundElevationOpacity"}
            sx={{
              color: isHeroVisible ? bgColor : "text",
              backgroundColor: isHeroVisible ? textColor : "backgroundElevationOpacity",
            }}
          />
        </NavRight>
      </Box>
    </Flex>
  );
};

const NavLeft = styled.div`
  text-align: left;
  overflow-x: auto;
  white-space: nowrap;
  text-align: center;
  -ms-overflow-style: none;
  scrollbar-width: none;
  & ::-webkit-scrollbar {
    display: none;
  }
`;

const Logo = styled.div`
  text-align: center;
  justify-content: center;
  display: flex;

  & > * {
    width: 100%;
    max-width: 3.2rem;
    margin-bottom: 1.6rem;
  }
  @media only screen and (min-width: 64rem) {
    text-align: left;
    justify-content: start;
    & > * {
      max-width: 4rem;
    }
  }
`;

const NavRight = styled.div`
  display: none;
  @media only screen and (min-width: 64rem) {
    text-align: right;
    display: block;
  }
`;

const MainNav = styled(Flex)<{ isScrolled: boolean }>`
  top: 0;
  right: auto;
  z-index: 9999;
  position: relative;
  transition: top 0.2s ease-in-out;

  width: calc(88vw + 8px);
  left: 50%;
  transform: translate(-50%, 0);

  backdrop-filter: blur(24px);
  border-radius: 99px;
  & > a {
    flex: 1 0 0;
    text-align: center;
  }
  @media only screen and (min-width: 40rem) {
    width: 394px;
    left: 50%;
    transform: translate(-50%, 0);
  }

  ${({ isScrolled }) =>
    isScrolled &&
    css`
      position: fixed;
      top: 0.8rem;
      @media only screen and (min-width: 64rem) {
        top: 1.6rem;
        width: 394px;
        left: 50%;
        transform: translate(-50%, 0);
      }
    `};
`;

export default memo(Header);
