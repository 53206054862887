/** @jsx jsx */
import { jsx, SxStyleProp, useColorMode, useThemeUI } from "theme-ui";
import { Button, Flex, Box } from "rebass";

export interface ThemeSwitchProps {
  sx?: SxStyleProp;
  bgColor?: string;
  textColor?: string;
}

const modes = ["light", "dark"];
//const modes = ["light", "dark", "pink"];

const ThemeSwitcher: React.FC<ThemeSwitchProps> = ({ sx, bgColor, textColor }) => {
  const [colorMode, setColorMode] = useColorMode();
  const { theme } = useThemeUI();

  return (
    <Flex sx={{ 
        justifyContent: 'flex-end', 
        ...sx
      }}>
      <Box sx={{ 
          backgroundColor: "backgroundElevationOpacity",
          borderRadius: "default",
        }}
      >
      {modes.map((mode) => (
        <Button
          key={mode}
          sx={{
            variant: "links.nav",
            fontSize: [1, 1, 2],
            bg: colorMode === mode ? textColor : "transparent",
            color: colorMode === mode ? bgColor : textColor,
            border: colorMode === mode ? "transparent" : "transparent",
            borderColor: colorMode === mode ? theme.colors?.borderColorQuiet : "transparent",
            "&:hover": {
              bg: colorMode === mode ? textColor : bgColor, // Unselected theme gets selected theme's styles on hover
              color: colorMode === mode ? bgColor : textColor,
              opacity: colorMode === mode ? 0.75 : 1, // Opacity change only for selected theme
            },
            m: 1, // Add some margin between the buttons
          }}
          onClick={() => setColorMode(mode)}
        >
          {mode.charAt(0).toUpperCase() + mode.slice(1)}
        </Button>
      ))}
      </Box>
    </Flex>
  );
};

export default ThemeSwitcher;
